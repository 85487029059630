import React from 'react';
import { HashLink } from 'react-router-hash-link';
import logo1 from '../images/logo.png'
const Footer = () => {
    return (
        <>
            <footer className="bg-gray-100 border-t">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-8">
        {/* Flex container for responsiveness */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Logo Section */}
          <div className="mb-4 md:mb-0">
            <HashLink smooth to="/#hero">
              <img src={logo1} alt="Company Logo" className="h-12" />
            </HashLink>
          </div>

          {/* Navigation Links */}
          <div className="flex space-x-6 mb-4 md:mb-0">
            <HashLink smooth to="/#about" className="text-blue-900 hover:text-gray-900">
              About
            </HashLink>
            <HashLink smooth to="/#services" className="text-blue-900 hover:text-gray-900">
              Services
            </HashLink>
            <HashLink smooth to="/contact" className="text-blue-900 hover:text-gray-900">
              Contact
            </HashLink>
          </div>

          {/* Social Media Icons */}
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-900 hover:text-gray-900"
            >
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                {/* Facebook Icon */}
                <path d="M22 12c0-5.522-4.477-10-10-10S2 6.478 2 12c0 4.991 3.657 9.128 8.438 9.877v-6.987h-2.54v-2.89h2.54V9.797c0-2.507 1.493-3.89 3.777-3.89 1.094 0 2.238.196 2.238.196v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562v1.875h2.773l-.443 2.89h-2.33v6.987C18.343 21.128 22 16.991 22 12z" />
              </svg>
            </a>
            <a
              href="https://www.twitter.com/#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-900 hover:text-gray-900"
            >
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                {/* Twitter Icon */}
                <path d="M22.46 6c-.77.35-1.6.58-2.46.69a4.28 4.28 0 001.88-2.37 8.59 8.59 0 01-2.72 1.04 4.27 4.27 0 00-7.3 3.89A12.14 12.14 0 013 5.77a4.27 4.27 0 001.32 5.7 4.22 4.22 0 01-1.94-.54v.05a4.27 4.27 0 003.42 4.18 4.28 4.28 0 01-1.93.07 4.27 4.27 0 003.99 2.97A8.57 8.57 0 012 19.54a12.08 12.08 0 006.29 1.84c7.55 0 11.68-6.25 11.68-11.68 0-.18-.01-.36-.02-.53A8.35 8.35 0 0022.46 6z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-900 hover:text-gray-900"
            >
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                {/* LinkedIn Icon */}
                <path d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.763 0 5-2.24 5-5v-14c0-2.76-2.237-5-5-5zm-11.75 19h-2.5v-8.5h2.5v8.5zm-1.25-9.75c-.83 0-1.5-.675-1.5-1.5s.67-1.5 1.5-1.5 1.5.675 1.5 1.5-.67 1.5-1.5 1.5zm13 9.75h-2.5v-4.25c0-1.01-.02-2.3-1.4-2.3-1.4 0-1.6 1.09-1.6 2.22v4.33h-2.5v-8.5h2.4v1.16h.03c.34-.65 1.17-1.34 2.4-1.34 2.56 0 3.03 1.68 3.03 3.86v4.82z" />
              </svg>
            </a>
          </div>
        </div>

        {/* Bottom area */}
        <div className="mt-8 text-center text-sm text-gray-600">
          © {new Date().getFullYear()} Vandelay Online. All rights reserved.
        </div>
      </div>
    </footer>
        </>
    )
}
export default Footer;
