import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id="portfolio">
  <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Portfolio</h2>
  <div className="flex justify-center">
    <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
  </div>

  <div className="px-4" data-aos="fade-down" data-aos-delay="600">
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
      {/* E-commerce Consultancy Services */}
      <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
        <div className="m-2 text-justify text-sm">
          <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
            E-commerce Consultancy Services
          </h4>
          <p className="text-md font-medium leading-5 h-auto md:h-48">
            Our E-commerce Consultancy Services are designed to provide expert guidance to businesses looking to excel on platforms like Amazon, Walmart, and eBay. We offer strategic planning, market analysis, and personalized advice to help you navigate the complexities of online marketplaces and achieve your business goals.
          </p>
          <div className="flex justify-center my-4">
            <Link
              to="/contact"
              className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
            >
              Schedule Consultation
              <svg
                className="w-4 h-4 ml-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Technical Support Services */}
      <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
        <div className="m-2 text-justify text-sm">
          <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
            Technical Support Services
          </h4>
          <p className="text-md font-medium leading-5 h-auto md:h-48">
            We offer round-the-clock Technical Support Services to ensure your online stores run smoothly. From troubleshooting to system optimizations, our team is dedicated to keeping your operations seamless and efficient.
          </p>
          <div className="flex justify-center my-4">
            <Link
              to="/contact"
              className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
            >
              Contact Support
              <svg
                className="w-4 h-4 ml-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Digital Marketing Solutions */}
      <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
        <div className="m-2 text-justify text-sm">
          <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
            Digital Marketing Solutions
          </h4>
          <p className="text-md font-medium leading-5 h-auto md:h-48">
            Our Digital Marketing Solutions help you stand out in the crowded online marketplace. We provide comprehensive marketing services, including SEO optimization, social media management, and targeted advertising campaigns to increase your brand visibility and drive sales.
          </p>
          <div className="flex justify-center my-4">
            <Link
              to="/contact"
              className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
            >
              Learn More
              <svg
                className="w-4 h-4 ml-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Inventory Management System */}
      <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
        <div className="m-2 text-justify text-sm">
          <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
            Inventory Management System
          </h4>
          <p className="text-md font-medium leading-5 h-auto md:h-48">
            Efficiently manage your stock levels with our Inventory Management System. Designed specifically for e-commerce businesses, this system helps you track inventory in real-time, forecast demand, and prevent stockouts or overstock situations.
          </p>
          <div className="flex justify-center my-4">
            <Link
              to="/contact"
              className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
            >
              Schedule Demo
              <svg
                className="w-4 h-4 ml-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* CRM Software */}
      <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
        <div className="m-2 text-justify text-sm">
          <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
            CRM Software
          </h4>
          <p className="text-md font-medium leading-5 h-auto md:h-48">
            Build lasting relationships with your customers using our CRM Software. It allows you to manage customer interactions, track sales leads, and improve customer service, all in one centralized platform.
          </p>
          <div className="flex justify-center my-4">
            <Link
              to="/contact"
              className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
            >
              Schedule Demo
              <svg
                className="w-4 h-4 ml-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Training and Workshops */}
      <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
        <div className="m-2 text-justify text-sm">
          <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
            Training and Workshops
          </h4>
          <p className="text-md font-medium leading-5 h-auto md:h-48">
            Stay ahead of the competition with our Training and Workshops. We offer educational programs on e-commerce best practices, platform updates, and strategies to enhance your online business performance.
          </p>
          <div className="flex justify-center my-4">
            <Link
              to="/contact"
              className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
            >
              Register Now
              <svg
                className="w-4 h-4 ml-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
}

export default Portfolio;