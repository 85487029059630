import React from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12">
  <section data-aos="zoom-in-down">
    <div className="my-4 py-4">
      <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Services</h2>
      <div className="flex justify-center">
        <div className="w-24 border-b-4 border-blue-900"></div>
      </div>
    </div>

    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
        {/* Amazon Seller Services */}
        <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:bg-blue-500 hover:text-white rounded-lg shadow-2xl p-3 group">
          <div className="m-2 text-justify text-sm">
            <img
              alt="Amazon Seller Services"
              className="rounded-t group-hover:scale-110 transition duration-1000 ease-in-out"
              src={img}
            />
            <h2 className="font-semibold my-4 text-2xl text-center">Amazon Seller Services</h2>
            <p className="text-md font-medium">
              Expert support for Amazon sellers, including account setup, product listing optimization, and advertising management to boost your sales and visibility.
            </p>
          </div>
        </div>

        {/* Walmart Marketplace Solutions */}
        <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:bg-blue-500 hover:text-white rounded-lg shadow-2xl p-3 group">
          <div className="m-2 text-justify text-sm">
            <img
              alt="Walmart Marketplace Solutions"
              className="rounded-t group-hover:scale-110 transition duration-1000 ease-in-out"
              src={img2}
            />
            <h2 className="font-semibold my-4 text-2xl text-center">Walmart Marketplace Solutions</h2>
            <p className="text-md font-medium">
              Seamless onboarding and support for selling on Walmart Marketplace, including compliance assistance and performance monitoring to maximize profitability.
            </p>
          </div>
        </div>

        {/* eBay Seller Assistance */}
        <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:bg-blue-500 hover:text-white rounded-lg shadow-2xl p-3 group">
          <div className="m-2 text-justify text-sm">
            <img
              alt="eBay Seller Assistance"
              className="rounded-t group-hover:scale-110 transition duration-1000 ease-in-out"
              src={img3}
            />
            <h2 className="font-semibold my-4 text-2xl text-center">eBay Seller Assistance</h2>
            <p className="text-md font-medium">
              Comprehensive services for eBay sellers, including store creation, branding, and global shipping guidance to enhance your online presence.
            </p>
          </div>
        </div>

        {/* Private & White Label Solutions */}
        <div className="bg-white transition-all duration-400 overflow-hidden text-gray-700 hover:bg-blue-500 hover:text-white rounded-lg shadow-2xl p-3 group">
          <div className="m-2 text-justify text-sm">
            <img
              alt="Private & White Label Solutions"
              className="rounded-t group-hover:scale-110 transition duration-1000 ease-in-out"
              src={img4}
            />
            <h2 className="font-semibold my-4 text-2xl text-center">Private & White Label Solutions</h2>
            <p className="text-md font-medium">
              From concept to creation, we assist in developing your private label products, including product research, brand identity, packaging, and launch strategies.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div className="m-auto max-w-6xl p-2 md:p-12">
      <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
        {/* Empower Section */}
        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
          <div className="text-blue-900 mb-4">
            {/* Empower Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
              viewBox="0 0 24 24"
              className="fill-current"
            >
              <path d="M12 2l3 7h8l-6.5 5.5L18 22l-6-4-6 4 2.5-7.5L2 9h8z"></path>
            </svg>
          </div>
          <h3 className="text-3xl text-blue-900 font-bold">
            We <span className="font-black">Empower</span>
          </h3>
          <div>
            <p className="my-3 text-xl text-gray-600 font-semibold">
              With extensive experience in e-commerce, we empower sellers to reach new markets and achieve significant growth on platforms like Amazon, Walmart, and eBay.
            </p>
          </div>
        </div>

        {/* Innovate Section */}
        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
          <div className="text-blue-900 mb-4">
            {/* Innovate Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
              viewBox="0 0 24 24"
              className="fill-current"
            >
              <path d="M12 2a7 7 0 00-7 7 7 7 0 004.95 6.74l.05.02.03.01A4.992 4.992 0 0012 22a4.992 4.992 0 002-9.23V13h-2v-2h2V9a2 2 0 012-2h2v2h-2v2h2v2h-2v.77A7 7 0 0019 9a7 7 0 00-7-7z"></path>
            </svg>
          </div>
          <h3 className="text-3xl text-blue-900 font-bold">
            We <span className="font-black">Innovate</span>
          </h3>
          <div>
            <p className="my-3 text-xl text-gray-600 font-semibold">
              We provide customized solutions tailored to your business needs, ensuring you stay ahead in the dynamic e-commerce landscape.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

    )
}

export default Services;